/* General Container Styling */
.app-container,
.signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

.signature-container {
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Signature Pad Styling */
.signature-pad {
    width: 100%;
    height: 100%;
    border: none;
    min-height: 450px;
    background-repeat: repeat;
}

/* Download Options Styling */
.btnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.download-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.download-button,
.save-button,
.clear-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; /* Smooth hover effect */

    &:hover {
        background-color: darken(red, 10%); /* Subtle hover darkening */
    }
}

.download-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Download Button Variants */
.download-button--png {
    background-color: #388e3c; /* Green */
}

.download-button--pdf {
    background-color: #1976d2; /* Blue */
}

.download-button--clear {
    background-color: #d32f2f; /* Red */
}

/* Save Button Styling */
.save-button {
    margin-top: 10px;
    background-color: #388e3c; /* Green */
    margin-bottom: 10px;
    color: #fff;

    &:hover {
        background-color: darken(#388e3c, 10%); /* Subtle hover darkening */
    }
}

/* Clear Button Styling */
.clear-button {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: red; /* Red */
    color: #fff;

    &:hover {
        background-color: darken(red, 10%); /* Subtle hover darkening */
    }
}

@media only screen and (max-width: 767px) {
    *,
    html {
        font-size: 95%;
    }
    .save-button,
    .clear-button {
        font-size: 95%;
    }
}

// Footers
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f5f5f5;
    padding: 10px;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    a {
        margin: 0;
        background-color: #f5f5f5;
        border-radius: 5px;
        font-weight: bold;
        color: #333;
        text-align: center;
    }
}

// Modal
/* Style.scss */
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .modal {
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    max-width: 500px;
    animation: entrance 0.5s ease-in-out forwards;
  
    @keyframes entrance {
      from {
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  
    .modal-title {
      font-weight: bold;
      margin-bottom: 10px;
      text-align: center;
    }
  
    .modal-body {
      line-height: 1.5;
    }
  
    .modal-list {
      list-style-type: none;
      padding: 0;
  
      li {
        margin-bottom: 10px;
        position: relative;
  
        .icon {
          display: inline-block;
          font-size: 1.5em;
          margin-right: 10px;
        }
      }
    }
  
    .modal-close {
      background-color: #333;
      color: #fff;
      margin-top: 20px;
      display: block;
      width: 100%;
    }
  
    // Media Query for smaller screens
    @media only screen and (max-width: 600px) {
      .modal {
        max-width: 80%;

    }
    li {
        font-size: 12px !important;
    }
    }
  }
  